import React from "react";
import styled from "styled-components";
import { EgsDownloadIcon, IosDownloadIcon } from "./Icons";
import steamWishlist from "../images/steam-wishlist.png";
import { ScreenSizes } from "../shared-code";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 25px;
  row-gap: 15px;
  text-align: center;
  color: #ffffff;

  @media (max-width: ${ScreenSizes.narrow}) {
    flex-direction: column;
  }

  & a {
    cursor: pointer;
  }

  & .Egs {
    padding-top: 1px;
  }

  & h2 {
    font-size: 64px;
    font-weight: 600;
    margin-block: 10px;

    @media (max-width: ${ScreenSizes.laptop}) {
      font-size: 44px;
    }

    @media (max-height: 820px) {
      font-size: 40px;
    }
  }
`;

const SteamWishlistImg = styled.img`
  height: 50px;

  @media (max-width: 449px) {
    height: 40px;
  }

  @media (max-height: 820px) {
    height: 35px;
  }
`;

const SteamWishlistLink = styled.a`
  margin-top: 10px;
`;

interface Props {
  isSmallScreen: boolean;
}

export default (props: Props) => {
  const iconsSize = props.isSmallScreen ? 40 : 50;
  // const iconsSize = 50;

  return (
    <Wrapper>
      <Row>
        <h2>PLAY NOW!</h2>
      </Row>
      <Row>
        <a href="https://store.epicgames.com/p/metalstorm" className="Egs">
          <EgsDownloadIcon iconSize={iconsSize - 4} />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.starform.metalstorm">
          <img
            src="https://starform-playmetalstorm-assets.s3.us-west-2.amazonaws.com/google-play-badge.png"
            alt="Google Play Store"
            height={iconsSize}
          />
        </a>
        <a href="https://apps.apple.com/app/metalstorm/id1461423243">
          <IosDownloadIcon iconSize={iconsSize} />
        </a>
      </Row>
      <Row>
        <SteamWishlistLink
          href="https://store.steampowered.com/app/2453200/Metalstorm/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <SteamWishlistImg src={steamWishlist} alt="" />
        </SteamWishlistLink>
      </Row>
    </Wrapper>
  );
};
