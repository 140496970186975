import React from "react";
import styled from "styled-components";

const BackgroundImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: no-repeat center
    url("https://starform-playmetalstorm-assets.s3.us-west-2.amazonaws.com/background.jpg");
  background-size: cover;
`;

const VideoStack = styled.div`
  background-color: rgb(13, 16, 20);
  z-index: 1;
`;

const VideoScrim = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #24265d;
  opacity: 0.65;
  z-index: 2;
  mix-blend-mode: multiply;
`;

const GradientScrim = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(
    to bottom,
    rgba(36, 38, 93, 0) 0%,
    rgba(36, 38, 93, 0) 75%,
    rgba(36, 38, 93, 1) 100%
  );
  z-index: 1;
  mix-blend-mode: multiply;
`;

const BackgroundVideoElement = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  z-index: 1;
`;

export let backgroundVideoRef: React.RefObject<HTMLVideoElement>;

export class BackgroundVideo extends React.Component {
  myRef: React.RefObject<HTMLVideoElement>;
  constructor(props: any) {
    super(props);
    this.myRef = React.createRef();
  }

  render() {
    let prefersReducedMotion = false;
    if (typeof window !== "undefined") {
      const mediaQuery = window.matchMedia("(prefers-reduced-motion: reduce)");
      prefersReducedMotion = mediaQuery.matches === true;
    }

    backgroundVideoRef = this.myRef;

    return prefersReducedMotion ? (
      <>
        <BackgroundImage />
      </>
    ) : (
      <VideoStack>
        <VideoScrim />
        <GradientScrim />
        <BackgroundVideoElement
          ref={this.myRef}
          controlsList="nodownload"
          src="https://starform-playmetalstorm-assets.s3.us-west-2.amazonaws.com/backgroundVideo.mp4"
          muted
          autoPlay
          loop
          playsInline
          disablePictureInPicture
        />
      </VideoStack>
    );
  }
}
