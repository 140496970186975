import React from "react";
import styled from "styled-components";

const VideoScrim = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: black;
  opacity: 0.8;
  z-index: 10;
`;

const StyledVideo = styled.video`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 40px);
  max-height: calc(100vh - 40px);
  max-width: 1000px;
  z-index: 11;
`;

export let popOverVideoRef: React.RefObject<HTMLVideoElement>;

export class PopOverVideo extends React.Component<{
  visible: boolean;
  closeVideo: () => void;
}> {
  myRef: React.RefObject<HTMLVideoElement>;
  constructor(props: any) {
    super(props);
    this.myRef = React.createRef();
  }

  closeVideo = () => {
    this.props.closeVideo();
    if (this.myRef.current !== null) {
      this.myRef.current.pause();
    }
  };

  render() {
    const display = this.props.visible ? "block" : "none";
    popOverVideoRef = this.myRef;
    return (
      <React.Fragment>
        <VideoScrim style={{ display: display }} onClick={this.closeVideo} />
        <StyledVideo
          style={{ display: display }}
          ref={this.myRef}
          poster="https://starform-playmetalstorm-assets.s3.us-west-2.amazonaws.com/background.jpg"
          controls
          disablePictureInPicture
          playsInline
          controlsList="nodownload"
        >
          <source src="https://starform-playmetalstorm-assets.s3.us-west-2.amazonaws.com/backgroundVideo.mp4" />
        </StyledVideo>
      </React.Fragment>
    );
  }
}
